/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

// SEO Related settings
const seo = {
  title: "Azeem Sarwar's Portfolio",
  description:
    "A Full Stack Developer with over two years of experience, delivering scalable and secure applications. Skilled in React, Node.js, and Firebase, and passionate about leveraging IoT and AI for connected systems.",
  og: {
    title: "Azeem Sarwar Portfolio",
    type: "website",
    url: "http://azeemsarwar.de/",
  },
};

// Home Page
const greeting = {
  title: "Azeem Sarwar",
  logo_name: "AzeemSarwar",
  nickname: "Versatile Developer",
  subTitle:
    "A Full Stack Developer with expertise in building scalable mobile and web apps, passionate about secure, high-performance solutions and currently pursuing a Master’s in IoT.",
  resumeLink:
    "https://drive.google.com/file/d/your_resume_link/view?usp=sharing",
  portfolio_repository: "https://github.com/azeem-sarwar",
  githubProfile: "https://github.com/azeem-sarwar",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/azeem-sarwar", // Update with your GitHub username
    fontAwesomeIcon: "fa-github",
    backgroundColor: "#181717",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/azeem-sarwarr/", // Update with your LinkedIn profile
    fontAwesomeIcon: "fa-linkedin-in",
    backgroundColor: "#0077B5",
  },
  // {
  //   name: "YouTube",
  //   link: "https://www.youtube.com/c/your-channel", // Update with your YouTube channel link
  //   fontAwesomeIcon: "fa-youtube",
  //   backgroundColor: "#FF0000",
  // },
  {
    name: "Gmail",
    link: "mailto:azeemsarwarr1@gmail.com", // Update with your email
    fontAwesomeIcon: "fa-google",
    backgroundColor: "#D14836",
  },
  {
    name: "X-Twitter",
    link: "https://x.com/AzeemSarwarr", // Update with your Twitter username
    fontAwesomeIcon: "fa-x-twitter",
    backgroundColor: "#000000",
  },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/your-facebook-username", // Update with your Facebook link
  //   fontAwesomeIcon: "fa-facebook-f",
  //   backgroundColor: "#1877F2",
  // },
  {
    name: "Instagram",
    link: "https://www.instagram.com/azeemsarwarr1/", // Update with your Instagram link
    fontAwesomeIcon: "fa-instagram",
    backgroundColor: "#E4405F",
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive web applications using React and Node.js",
        "⚡ Developing mobile applications using React Native and Expo",
        "⚡ Creating scalable backend services with Express and MongoDB",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "React Native",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#000",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:fireship",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Stripe",
          fontAwesomeClassname: "simple-icons:stripe",
          style: {
            color: "#6772E5",
          },
        },
      ],
    },
    {
      title: "Cloud Infrastructure & DevOps",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience deploying applications on AWS and GCP",
        "⚡ Managing cloud resources and services for optimal performance",
        "⚡ Implementing CI/CD pipelines for seamless integration and delivery",
      ],
      softwareSkills: [
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#F05032",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Creating intuitive and engaging user interfaces",
        "⚡ Conducting user research to inform design decisions",
        "⚡ Prototyping and testing designs for improved user experience",
      ],
      softwareSkills: [
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/u/azeemsarwarr1/",
    },
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/layman_brother",
    // },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/azeemsarwar",
    },

    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@azeemsarwarr1/",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/azeemsarwar12",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Hochschule university of applied sciences Germany",
      subtitle: "M.S. in Internet of things",

      logo_path: "hochshule.png",
      alt_name: "Hochschule university of applied sciences",
      duration: "2024 until 2026",
      descriptions: [
        "⚡ Currently pursuing my Master's in IoT at Hochschule Nordhausen with a focus on connected systems, real-time applications, and AI-driven solutions.",
        "⚡ I have taken courses in Smart Devices, Embedded Systems, and AI, applying these concepts to real-world IoT projects.",
        "⚡ I am exploring advanced topics such as real-time data processing, edge computing, and AI-based automation in IoT systems.",
        "⚡ My research includes developing smart home automation solutions using AI and machine learning for enhanced security and efficiency.",
      ],
      website_link: "https://www.hs-nordhausen.de/",
    },
    {
      title: "Arid Agriculture university Rawalpindi Pakistan",
      subtitle: "BS in Software Engineering",
      logo_path: "arid.png",
      alt_name: "Indiana University Bloomington",
      duration: "2018 - 2022",
      descriptions: [
        "⚡ I completed my Bachelor's in Software Engineering with a CGPA of 3.92/4.00, earning a Gold Medal for academic excellence.",
        "⚡ During my degree, I developed a variety of mobile and web applications using React Native, React.js, Node.js, and Firebase.",
        "⚡ I led my final year project, which involved creating a real-time communication app, focusing on user-friendly interfaces and secure data handling.",
        "⚡ In addition, I contributed to several side projects, including a social networking app and an e-commerce platform with Stripe integration.",
      ],
      website_link: "https://www.uaar.edu.pk/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Python",
      subtitle: "Kaggle Python course",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.kaggle.com/learn/certification/azeemsarwar12/python",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship, and Volunteership",
  description:
    "I am a Full Stack Developer with experience in building scalable web and mobile applications. My expertise includes integrating third-party services and payment systems, designing efficient UIs, and handling end-to-end app development from concept to deployment. I have worked with startups and established companies alike, and I am currently pursuing a Master’s in IoT to expand my expertise in AI-driven solutions and connected systems.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Full Stack Web and Mobile Developer",
          company: "Mid-Chain Technologies",
          company_url: "https://www.mid-chaintechnologies.com/team.php",
          logo_path: "mid_chain_logo.png",
          duration: "July 2023 - Present",
          location: "On-Site",
          description:
            "Developed and deployed web and mobile applications using React Native and React.js. Built secure file-sharing apps with in-app purchases and integrated Stripe for payment processing. Led development of a job-posting platform and optimized app performance, improving user satisfaction.",
          color: "#000000",
        },
        {
          title: "Full Stack Web Developer",
          company: "Upwork And Fiver",
          company_url: "https://www.fiverr.com/s/2KyvrWN",
          logo_path: "upwork.png",
          duration: "June 2022 - August 2023",
          location: "Remote",
          description:
            "Delivered full-stack web applications for various clients, focusing on scalability and security. Developed a wholesale tea sale app and built file-sharing platforms with end-to-end encryption, enhancing security and usability.",
          color: "#6fda44",
        },
        {
          title: "Intern Mobile App Developer",
          company: "Alfarj Solutions",
          company_url: "https://afssquare.com/",
          logo_path: "afs_logo.webp",
          duration: "June 2021 - May 2023",
          location: "On-Site",
          description:
            "Worked on mobile app development using React Native. Integrated real-time features such as Google Maps and chat functionalities. Contributed to backend systems, enhancing scalability and security for various projects.",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Mobile App Developer Intern",
          company: "Alfarj Solutions",
          company_url: "https://afssquare.com/",
          logo_path: "afs_logo.webp",
          duration: "June 2021 - May 2023",
          location: "On-Site",
          description:
            "Worked on building mobile applications with real-time functionalities using React Native. Focused on backend integration and improving app performance.",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Open Source Contributor",
          company: "GitHub",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "Ongoing",
          location: "Remote",
          description:
            "Contributing to various open-source projects, including bug fixes, feature enhancements, and improvements in documentation. Active participant in community-driven projects and collaborative development.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    // {
    //   id: "neuro-symbolic-sudoku-solver",
    //   name: "Neuro-Symbolic Sudoku Solver",
    //   createdAt: "2023-07-02T00:00:00Z",
    //   description: "Paper published in KDD KiML 2023",
    //   url: "#",
    // },
    // {
    //   id: "mdp-diffusion",
    //   name: "MDP-Diffusion",
    //   createdAt: "2023-09-19T00:00:00Z",
    //   description: "Blog published in Paperspace",
    //   url: "#",
    // },
    // {
    //   id: "consistency-models",
    //   name: "Consistency Models",
    //   createdAt: "2023-10-12T00:00:00Z",
    //   description: "Blog published in Paperspace",
    //   url: "#",
    // },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "azeem.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with react-native, python, React, Android, Cloud and fullstack Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "https://blog.azeemsarwar.de/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "bochumer strasse.32, Nordhausen, Thuringia, germany 99734",
    locality: "bochumer strasse.32",
    country: "Nordhausen",
    region: "Thuringia",
    postalCode: "99734",
    streetAddress: "Saratoga Avenue",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/pzgZsvPGFKakguJC6",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
