import React from "react";
import "./LoaderLogo.css";

import Lottie from "react-lottie";
import Animation from "./Animation - 1728983054485.json";

class LogoLoader extends React.Component {
  render() {
    const theme = this.props.theme;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: Animation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Lottie
        options={defaultOptions}
        height={450}
        width={600}
        isStopped={false}
        isPaused={false}
        color={theme === "dark" ? "white" : "black"}
        // theme={theme === "dark" ? "dark" : "light"}
      />
    );
  }
}

export default LogoLoader;
